import React, { useState, useEffect } from "react"
import { CSSTransition } from "react-transition-group"
import Div100vh from 'react-div-100vh'
import { useTranslation } from 'gatsby-plugin-react-i18next'

import { Button } from "../buttons"

import font from "../../fonts/fonts.module.scss"
import styled from "styled-components"
import { device } from '../../components/device'

import setaC from "../../images/setaC.svg"
import setaB from "../../images/setaB.svg"

import {  I18nextContext } from 'gatsby-plugin-react-i18next'

const VinhoModal = ({ data }) => {
  const { t } = useTranslation(['vinhos']);

  const [info, setInfo] = useState(0);
  const [colheita, setColheita] = useState(0);
  const [reverseTransition, setReverseTransition] = useState(true);
  const [isScrolling, setIsScrolling] = useState(false);
  const [touchStart, setTouchStart] = useState(null);
  const [touchCurrent, setTouchCurrent] = useState(null);
  const [vinho, setVinho] = useState(data)

  let listaVinhos = []
  listaVinhos = listaVinhos.concat(
    t('docs.bairrada', { returnObjects: true }),
    t('docs.dao', { returnObjects: true }),
    t('docs.douro', { returnObjects: true }),
    t('docs.setubal', { returnObjects: true }),
    t('docs.palmela', { returnObjects: true }),
    t('docs.porto', { returnObjects: true }),
    t('docs.tejo', { returnObjects: true }),
    t('docs.verde', { returnObjects: true }),
    t('regionais.alentejo', { returnObjects: true }),
    t('regionais.mesa', { returnObjects: true }),
    t('regionais.minho', { returnObjects: true }),
    t('regionais.setubal', { returnObjects: true }),
    t('regionais.tejo', { returnObjects: true })
  )

  useEffect(() => {
    listaVinhos.map((vinho) => (
      data.nome === vinho.nome && setVinho(vinho)
    ))
    // eslint-disable-next-line
  }, [])

  function touchMove() {
    if (touchCurrent < touchStart && !isScrolling) {
      if (vinho.premios.length === 0) {
        if (info !== 2) {
          setInfo(info + 1);
          setReverseTransition(true);
          setIsScrolling(true);
        }
      }
      else {
        if (info !== 3) {
          setInfo(info + 1);
          setReverseTransition(true);
          setIsScrolling(true);
        }
      }
    }
    else if (touchCurrent > touchStart && !isScrolling) {
      if (info !== 0) {
        setInfo(info - 1);
        setReverseTransition(false);
        setIsScrolling(true);
      }
    }
  }

  useEffect(() => {
    if (isScrolling) {
      setTimeout(function () {
        setIsScrolling(false);
      }, 350);
    }
  }, [isScrolling]);

  const changeEvent = e => {
    let y = e.deltaY;
    if (vinho.premios.length > 0) {
      if (y > 0 && !isScrolling && info !== 3) {
        setInfo(info + 1);
        setReverseTransition(true)
        setIsScrolling(true);
      }
      else if (y < 0 && !isScrolling && info !== 0) {
        setInfo(info - 1);
        setReverseTransition(false)
        setIsScrolling(true)
      }
    } else {
      if (y > 0 && !isScrolling && info !== 2) {
        setInfo(info + 1);
        setReverseTransition(true)
        setIsScrolling(true);
      }
      else if (y < 0 && !isScrolling && info !== 0) {
        setInfo(info - 1);
        setReverseTransition(false)
        setIsScrolling(true)
      }
    }
  }

  const activeLanguage = React.useContext(I18nextContext).language

  return (
    <StyledVinho id="infoPanel" onWheel={(e) => changeEvent(e)} onTouchStart={(e) => { setTouchStart(e.touches[0].clientY); setTouchCurrent(e.touches[0].clientY) }} onTouchMove={(e) => (setTouchCurrent(e.touches[0].clientY))} onTouchEnd={touchMove}>
      <div className="infoPanel">
        <h1 className={font.aGGD + " bigAno"}>{vinho.ano !== "---" ? vinho.ano : ""}</h1>
        <div className="vinhoImg">
          <img src={data.img.publicURL} alt="Garrafa" style={{ width: `100%`, height: "100%" }} imgStyle={{ objectFit: "contain" }} className="vinhoImg" />
        </div>

        <div className="info">
          {/* primeiro painel */}
          <CSSTransition in={info === 0} timeout={350} classNames={reverseTransition ? "switchModal" : "switchReverse"} unmountOnExit>
            <div className="infoContent">
              <h2 className={font.nSR + " vinhoNome"}>{vinho.nome}</h2>
              <hr />
              {activeLanguage === 'pt' ? <p className={font.uR + " cardEsgotado"} style={{color: "#D82A17"}}> {data.esgotado_pt}</p> :
                activeLanguage === 'en' ? <p className={font.uR + " cardEsgotado"} style={{color: "#D82A17"}}> {data.esgotado_en}</p> :
                  activeLanguage === 'fr' && <p className={font.uR + " cardEsgotado"} style={{color: "#D82A17"}}> {data.esgotado_fr}</p>}
              <hr />
              <div className="tipoAno">
                <p className={font.aGGBO + " vinhoTipo"}>{vinho.tipo} - {vinho.regiao}</p>
                <p className={font.uR + " vinhoAno"}>{vinho.ano !== "---" ? vinho.ano : ""}</p>
              </div>
              <hr />
              <p className={font.nSI + " painel1Txt"}>
                {vinho.castas !== "---" && <><span className={font.aGGD + " painel1Titles"}>{t('infoTitles.castas')}</span>{vinho.castas}</>}
                {vinho.cor !== "---" && <><span className={font.aGGD + " painel1Titles"}>{t('infoTitles.cor')}</span>{vinho.cor}</>}
                {vinho.aroma !== "---" && <><span className={font.aGGD + " painel1Titles"}>{t('infoTitles.aroma')}</span>{vinho.aroma}</>}
                {vinho.solo !== "---" && <><span className={font.aGGD + " painel1Titles"}>{t('infoTitles.solo')}</span>{vinho.solo}</>}
                {vinho.clima !== "---" && <><span className={font.aGGD + " painel1Titles"}>{t('infoTitles.clima')}</span>{vinho.clima}</>}
                {vinho.boca !== "---" && <><span className={font.aGGD + " painel1Titles"}>{t('infoTitles.boca')}</span>{vinho.boca}</>}
                {vinho.bocaFinal !== "---" && <><span className={font.aGGD + " painel1Titles"}>{t('infoTitles.bocaFinal')}</span>{vinho.bocaFinal}</>}
                {vinho.pratos !== "---" && <><span className={font.aGGD + " painel1Titles"}>{t('infoTitles.pratos')}</span>{vinho.pratos}</>}
              </p>
            </div>
          </CSSTransition>

          {/* segundo painel */}
          <CSSTransition in={info === 1} timeout={350} classNames={reverseTransition ? "switchModal" : "switchReverse"} unmountOnExit>
            <div className="infoContent">
              <h2 className={font.nSR + " vinhoNome"}>{vinho.nome}</h2>
              <hr />
              <div className="tipoAno">
                <p className={font.aGGBO + " vinhoTipo"}>{vinho.tipo} - {vinho.regiao}</p>
                <p className={font.uR + " vinhoAno"}>{vinho.ano !== "---" ? vinho.ano : ""}</p>
              </div>
              <hr />
              <p className={font.nSI + " painel1Txt"}>
                {vinho.enologo !== "---" && <><span className={font.aGGD + " painel1Titles"}>{t('infoTitles.enologo')}</span>{vinho.enologo}</>}
                <br /><br />
                {vinho.vinificacao !== "---" && <><span className={font.aGGD + " painel1Titles"}>{t('infoTitles.vinificacao')}</span>{vinho.vinificacao}</>}
                {vinho.alcool !== "---" && <><span className={font.aGGD + " painel1Titles"}>{t('infoTitles.alcool')}</span>{vinho.alcool}</>}
                {vinho.acucar !== "---" && <><span className={font.aGGD + " painel1Titles"}>{t('infoTitles.acucar')}</span>{vinho.acucar}</>}
                {vinho.pH !== "---" && <><span className={font.aGGD + " painel1Titles"}>{t('infoTitles.ph')}</span>{vinho.pH}</>}
                {vinho.temperatura !== "---" && <><span className={font.aGGD + " painel1Titles"}>{t('infoTitles.temperatura')}</span>{vinho.temperatura}</>}
              </p>
            </div>
          </CSSTransition>

          {/* terceiro painel */}
          <CSSTransition in={vinho.premios.length > 0 && info === 2} timeout={350} classNames={reverseTransition ? "switchModal" : "switchReverse"} unmountOnExit>
            <div className="infoContent">
              <h2 className={font.nSR + " vinhoNome"}>{vinho.nome}</h2>
              <hr />
              <div className="tipoAno">
                <p className={font.aGGBO + " vinhoTipo"}>{vinho.tipo} - {vinho.regiao}</p>
                <p className={font.uR + " vinhoAno"}>{vinho.ano !== "---" ? vinho.ano : ""}</p>
              </div>
              <hr />
              <p className={font.aGGBO + " vinhoTipo"}>{t('infoTitles.premios')}</p>
              <div className="premiosAnos">
                {vinho.premios.map((vinho, i) => (
                  <Button styleless className={colheita === i ? (font.uR + " colheita colheita-active") : (font.uR + " colheita")} key={"colheita" + i} onClick={() => { setColheita(i) }}>{vinho.ano}</Button>
                ))}
              </div>
              <div className="premiosDisplay">
                {vinho.premios.map((vinho, i) => (
                  <p className={font.nSI + " painel1Txt premios premios" + i} key={"premiosAno" + i} style={colheita === i ? { opacity: "1" } : { opacity: "0" }}>
                    {vinho.premios.map((vinho, i) => (
                      <span key={"premios" + i}><span className={font.aGGD + " painel1Titles"}>{vinho.prize} - </span> {vinho.source}<br /></span>
                    ))}
                  </p>
                ))}
              </div>
            </div>
          </CSSTransition>

          {/* quarto painel */}
          <CSSTransition in={vinho.premios.length > 0 ? info === 3 : info === 2} timeout={350} classNames={reverseTransition ? "switchModal" : "switchReverse"} unmountOnExit>
            <div className="infoContent">
              <h2 className={font.nSR + " vinhoNome"}>{vinho.nome}</h2>
              <hr />
              <div className="tipoAno">
                <p className={font.aGGBO + " vinhoTipo"}>{vinho.tipo} - {vinho.regiao}</p>
                <p className={font.uR + " vinhoAno"}>{vinho.ano !== "---" ? vinho.ano : ""}</p>
              </div>
              <hr />
              <table>
                <tbody>
                  {vinho.capacidade !== "---" && <tr><td className={font.aGGB + " table1"}>{t('infoTitles.capacidade')}</td><td className={font.nSI + " table2"}>{vinho.capacidade}</td></tr>}
                  {vinho.unidadesCaixa !== "---" && <tr><td className={font.aGGB + " table1"}>{t('infoTitles.garrafasCaixa')}</td><td className={font.nSI + " table2"}>{vinho.unidadesCaixa}</td></tr>}
                  {vinho.pesoLiquido !== "---" && <tr><td className={font.aGGB + " table1"}>{t('infoTitles.pesoCaixaLiquido')}</td><td className={font.nSI + " table2"}>{vinho.pesoLiquido}</td></tr>}
                  {vinho.pesoBruto !== "---" && <tr><td className={font.aGGB + " table1"}>{t('infoTitles.pesoCaixaBruto')}</td><td className={font.nSI + " table2"}>{vinho.pesoBruto}</td></tr>}
                  {vinho.dimensoes !== "---" && <tr><td className={font.aGGB + " table1"}>{t('infoTitles.dimensoes')}</td><td className={font.nSI + " table2"}>{vinho.dimensoes}</td></tr>}
                  {vinho.caixasPalete !== "---" && <tr><td className={font.aGGB + " table1"}>{t('infoTitles.caixasPalete')}</td><td className={font.nSI + " table2"}>{vinho.caixasPalete}</td></tr>}
                  {vinho.pesoPalete !== "---" && <tr><td className={font.aGGB + " table1"}>{t('infoTitles.pesoPalete')}</td><td className={font.nSI + " table2"}>{vinho.pesoPalete}</td></tr>}
                </tbody>
              </table>
            </div>
          </CSSTransition>
        </div>
        <div className="moreInfo">
          {info > 0 &&
            <Button styleless onClick={() => { setReverseTransition(false); setInfo(info - 1); }}>
              <div className="infoUp">
                <img src={setaC} alt="Informações Anteriores" />
              </div>
            </Button>
          }
          <h5 className={font.nSI + " moreInfoTitle"}>{t('modalInfoTitles', { returnObjects: true })[(vinho.premios.length === 0 && info === 2) ? (info + 1) : info]}</h5>
          <p className={font.uR + " moreInfoPage"}>{"0" + (info + 1)} / {vinho.premios.length === 0 ? "03" : "04"}</p>
          <Button styleless className={vinho.premios.length === 0 && info < 2 ? "visible" : vinho.premios.length > 0 && info < 3 ? "visible" : "invisible"} onClick={() => { setReverseTransition(true); setInfo(info + 1) }}>
            <div className="infoDown">
              <img src={setaB} alt="Próximas Informações" />
            </div>
          </Button>
        </div>

        {vinho.ficha !== '' && <a href={vinho.ficha} target='_blank' rel='noreferrer'><Button className='ficha-tecnica' >{t('infoTitles.fichaTecnica')}</Button></a>}
      </div>
    </StyledVinho>
  )
}

export default VinhoModal

const StyledVinho = styled(Div100vh)`
  position: relative;
  width: 100%;
  top: 0vh;
  transition: top 350ms ease-out;
  
  .invisible{
    visibility: hidden;
  }

  .ficha-tecnica{
    border-radius:0;
    padding:10px 30px;
    font-family: 'NSTR';
    font-size:15px;
    letter-spacing:0;
    position: absolute;
    bottom: 1vw;
    right: 1vw;
    transition: all 0.3s;

    &:hover{
      background:#fff;
      color:#3e3028;
    }
  }

  .infoPanel{
    display: flex;
    align-items: center;
    height: 100%;
    width: 100%;
    background: rgb(62,48,40);
    background: linear-gradient(180deg, rgba(62,48,40,1) 0%, rgba(62,48,40,1) 50%, rgba(31,24,19,1) 100%);
    position: relative;
    overflow: hidden;
    color: #fff8f4;

    .bigAno{
      position: absolute;
      top: 0;
      left: -0.8vw;
      color: #150e03;
      opacity: 0.5;
      font-size: calc(17.5vw * 0.8435);
      line-height: 11vw;
    }

    .vinhoImg{
      width: 43%;
      height: auto;
      padding: 9% 5% 5% 5%;
      display: flex;
      z-index: 1;
      img{height: 100%;}
    }

    .info{
      display:flex;
      flex-direction:column-reverse;
      position: relative;
      height: 60vh;
      width:50%;
      overflow-y: auto;

      hr{
        border: 1px solid rgba(21, 14, 3, 0.6);
      }

      .tipoAno{
        display: flex;
        justify-content: space-between;
      }

      .infoContent{
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        overflow-y:auto;
        -webkit-overflow-scrolling: touch;
        padding-right:10px;

        .vinhoNome{
          font-size:30px;
          line-height:44px;

          @media ${device.desktopL}{
            font-size:42px;      
          }
        }

        .painel2Txt{
          margin-bottom: 20px;
        }

        .vinhoTipo{
          font-size:15px;

          @media ${device.desktopL}{
            font-size: 18px;
          }
        }
        .vinhoAno{
          font-size: 16px;
          display:flex;
          align-items:center;

          @media ${device.desktop}{

          }
        }
        .painel1Txt{
          font-size: 16px;
          letter-spacing:unset !important;

          @media ${device.desktopL}{
            font-size: 18px;
          }
        }

        .painel1Titles{
          font-size: 15px;
        }

        .premiosAnos{
          margin: 20px 0;
          display: grid;
          grid-template-columns: repeat(5, 1fr);

          .colheita{
            font-size: 16px;
            height: 2.5em;
            border: 1px solid #150e03;
            display: flex;
            align-items: center;
            text-align: center;
            justify-content: center;
            background-color: inherit;
            transition: all 0.3s ease;

            &:hover{
              background:#fff;
              color:#3e3028;
            }
          }

          .colheita-active{
            background:#fff;
            color:#3e3028;
          }
        }
      }

      .infoContent::-webkit-scrollbar {
        width: 3px;
        background-color: #3e3028;
      }
      .infoContent::-webkit-scrollbar-thumb {
          height: 6px;
          background-clip: padding-box;
          background-color: #fff;
          box-shadow: inset -1px -1px 0px rgba(0, 0, 0, 0.05), inset 1px 1px 0px rgba(0, 0, 0, 0.05);
      }
      .infoContent::-webkit-scrollbar-button {
          width: 0;
          height: 0;
          display: none;
      }
      .infoContent::-webkit-scrollbar-corner {
          background-color: transparent;
      }

      .premiosDisplay{
        position: relative;
        min-height: 15vh;

        .premios{
          position: absolute;
        }
      }

      table{
        width: 100%;
        padding: 0;
        border-spacing: 0;
        border-top: 2px solid rgba(21, 14, 3, 0.5);
        font-size: 18px;
        vertical-align: middle;

        td{
          font-size:15px;
        }
        
        .table1{
          width: 66%;
          margin: 0;
          border-left: 2px solid rgba(21, 14, 3, 0.5);
          border-bottom: 2px solid rgba(21, 14, 3, 0.5);
          padding: 0 15px;
          height: 2.5em;
        }

        .table2{
          width: 34%;
          text-align: center;
          margin: 0;
          border-right: 2px solid rgba(21, 14, 3, 0.5);
          border-left: 2px solid rgba(21, 14, 3, 0.5);
          border-bottom: 2px solid rgba(21, 14, 3, 0.5);
          height: 2.5em;
        }
      }
    }

    .info::-webkit-scrollbar {
      width: 3px;
      background-color: #3e3028;
    }
    .info::-webkit-scrollbar-thumb {
        height: 6px;
        background-clip: padding-box;
        background-color: #fff;
        box-shadow: inset -1px -1px 0px rgba(0, 0, 0, 0.05), inset 1px 1px 0px rgba(0, 0, 0, 0.05);
    }
    .info::-webkit-scrollbar-button {
        width: 0;
        height: 0;
        display: none;
    }
    .info::-webkit-scrollbar-corner {
        background-color: transparent;
    }
  }

  .moreInfo{
    position: absolute;
    bottom: 20px;
    left: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    transform: translateX(-50%);

    .moreInfoTitle{
      color: #fa4f4f;
      font-size: 18px;
    }

    .moreInfoPage{
      font-size: 16px;
    }

    .inv{
      visibility: hidden;
      user-select: none;
    }
  }

  .logoImg{
    position: absolute;
    bottom: 1vw;
    right: 1vw;
    width: 8vw;
  }

  /* react-transition-group */
  .switchReverse-enter {
    position: absolute;
    top: 0% !important;
    transform: translateY(-50%);
    opacity: 0;
  }
  .switchReverse-enter-active {
    position: absolute;
    top: 50% !important;
    transform: translateY(-50%);
    opacity: 1;
    transition: all 350ms ease-out;
  }
  .switchReverse-exit {
    position: absolute;
    top: 50% !important;
    transform: translateY(-50%);
    opacity: 1;
  }
  .switchReverse-exit-active{
    position: absolute;
    top: 100% !important;
    transform: translateY(-50%);
    opacity: 0;
    transition: all 350ms ease-out;
  }
  .switchModal-enter{
    position: absolute;
    top: 100% !important;
    transform: translateY(-50%);
    opacity: 0;
  }
  .switchModal-enter-active{
    position: absolute;
    top: 50% !important;
    transform: translateY(-50%);
    opacity: 1;
    transition: all 350ms ease-out;
  }
  .switchModal-exit{
    position: absolute;
    top: 50% !important;
    transform: translateY(-50%);
    opacity: 1;
  }
  .switchModal-exit-active{
    position: absolute;
    top: 0% !important;
    transform: translateY(-50%);
    opacity: 0;
    transition: all 350ms ease-out;
  }
`