import React, { useState, useEffect } from "react"
import { CSSTransition } from "react-transition-group"
import Div100vh from 'react-div-100vh'
import { useTranslation} from 'gatsby-plugin-react-i18next'

import { Button } from "../buttons"

import font from "../../fonts/fonts.module.scss"
import styled from "styled-components"
import Slider from "react-slick"
import { device } from '../../components/device'

import setaE from "../../images/setaE.svg"
import setaD from "../../images/setaD.svg"

const VinhoModal = ({ data }) => {
  const [colheita, setColheita] = useState(0)
  const {t} = useTranslation(['vinhos'])
  const [vinho, setVinho] = useState(data)

    
  let listaVinhos = []
  listaVinhos = listaVinhos.concat(
    t('docs.bairrada', {returnObjects: true}), 
    t('docs.dao', {returnObjects: true}), 
    t('docs.douro', {returnObjects: true}),
    t('docs.setubal', {returnObjects: true}),
    t('docs.palmela', {returnObjects: true}),
    t('docs.porto', {returnObjects: true}),
    t('docs.tejo', {returnObjects: true}),
    t('docs.verde', {returnObjects: true}),
    t('regionais.alentejo', {returnObjects: true}),
    t('regionais.mesa', {returnObjects: true}),
    t('regionais.minho', {returnObjects: true}),
    t('regionais.setubal', {returnObjects: true}),
    t('regionais.tejo', {returnObjects: true})
  )

  useEffect(() => {
    listaVinhos.map((vinho) => (
      data.nome === vinho.nome && setVinho(vinho)
    ))
  // eslint-disable-next-line
  }, [])

  const PrevArrow = (props) => {
    const { onClick } = props
    return(
      <button className='prev-arrow' onClick={onClick}>
        <img src={setaE} alt="Slide Anterior"/>
      </button>
    )
  }

  const NextArrow = (props) => {
    const { onClick } = props
    return(
      <button className='next-arrow' onClick={onClick}>
        <img src={setaD} alt="Próximo Slide"/>
      </button>
    )
  }

  const settings = {
    dots: false,
    arrows: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    pauseOnHover: false,
    swipeToSlide: true,
    infinite:false,
    nextArrow: <NextArrow/>,
    prevArrow: <PrevArrow/>,
  }

  return(
    <StyledVinho id="infoPanel" bgFixed={data.img.publicURL}>
      <div className="first-panel">
        <div className="vinhoImg">
          <img src={data.img.publicURL} alt="Garrafa" style={{ width: `100%`, height: "100%" }} imgStyle={{ objectFit: "contain" }} />
        </div>
        <div className='first-content'>
          <h2 className={font.nSR + " vinhoNome"}>{vinho.nome}</h2>
          <div className="tipoAno">
            <p className={font.aGGBO + " vinhoTipo"}>{vinho.tipo} - {vinho.regiao}</p>
            <p className={font.uR + " vinhoAno"}>{vinho.ano !== "---" ? vinho.ano : ""}</p>
          </div>
        </div>
        <h1 className={font.aGGD + " bigAno"}>{vinho.ano !== "---" ? vinho.ano : ""}</h1>
        {vinho.ficha !== '' && <a href={vinho.ficha} className='ficha-tecnica-link' target='_blank' rel='noreferrer'><Button className='ficha-tecnica' >Ficha Técnica</Button></a>}
      </div>

      <div className='second-panel'>
        <h5 className={font.nSI + " moreInfoTitle"}>{t('modalInfoTitles', {returnObjects:true})[0]}</h5>
        <div className='info'>
          <CSSTransition>
              <div className="infoContent">

                <Slider {...settings}>
                  {/* FIRST SLIDE */}
                  <div>
                    <p className={font.nSI + " painel1Txt"}>
                    {vinho.castas!=="---" && <><span className={font.aGGD + " painel1Titles"}>{t('infoTitles.castas')}</span>{vinho.castas}</>}
                    {vinho.cor!=="---" && <><span className={font.aGGD + " painel1Titles"}>{t('infoTitles.cor')}</span>{vinho.cor}</>}
                    {vinho.aroma!=="---" && <><span className={font.aGGD + " painel1Titles"}>{t('infoTitles.aroma')}</span>{vinho.aroma}</>}
                    {vinho.solo!=="---" && <><span className={font.aGGD + " painel1Titles"}>{t('infoTitles.solo')}</span>{vinho.solo}</>}
                    {vinho.clima!=="---" && <><span className={font.aGGD + " painel1Titles"}>{t('infoTitles.clima')}</span>{vinho.clima}</>}
                    {vinho.boca!=="---" && <><span className={font.aGGD + " painel1Titles"}>{t('infoTitles.boca')}</span>{vinho.boca}</>}
                    {vinho.bocaFinal!=="---" && <><span className={font.aGGD + " painel1Titles"}>{t('infoTitles.bocaFinal')}</span>{vinho.bocaFinal}</>}
                    {vinho.pratos!=="---" && <><span className={font.aGGD + " painel1Titles"}>{t('infoTitles.pratos')}</span>{vinho.pratos}</>}
                  </p>
                  </div>

                  {/* SECOND SLIDE */}
                  <div>
                    <p className={font.nSI + " painel1Txt"}>
                      {vinho.enologo!=="---" && <><span className={font.aGGD + " painel1Titles"}>{t('infoTitles.enologo')}</span>{vinho.enologo}</>}
                      <br/><br/>
                      {vinho.vinificacao!=="---" && <><span className={font.aGGD + " painel1Titles"}>{t('infoTitles.vinificacao')}</span>{vinho.vinificacao}</>}
                      {vinho.alcool!=="---" && <><span className={font.aGGD + " painel1Titles"}>{t('infoTitles.alcool')}</span>{vinho.alcool}</>}
                      {vinho.acucar!=="---" && <><span className={font.aGGD + " painel1Titles"}>{t('infoTitles.acucar')}</span>{vinho.acucar}</>}
                      {vinho.pH!=="---" && <><span className={font.aGGD + " painel1Titles"}>{t('infoTitles.ph')}</span>{vinho.pH}</>}
                      {vinho.temperatura!=="---" && <><span className={font.aGGD + " painel1Titles"}>{t('infoTitles.temperatura')}</span>{vinho.temperatura}</>}
                    </p>
                  </div>

                  {/* THIRD SLIDE */}
                  {vinho.premios.length > 0 &&
                    <>
                    <div>
                      <p className={font.aGGBO + " vinhoTipo"}>{t('infoTitles.premios')}</p>
                      <div className="premiosAnos">
                        {vinho.premios.map((vinho, i) => (
                          <Button styleless className={colheita===i ? (font.uR + " colheita colheita-active") : (font.uR + " colheita")} key={"colheita" + i} onClick={() => {setColheita(i)}}>{vinho.ano}</Button>
                        ))}
                      </div>
                      <div className="premiosDisplay">
                        {vinho.premios.map((vinho, i) => (
                          <p className={font.nSI + " painel1Txt premios premios" + i} key={"premiosAno" + i} style={colheita === i ? {opacity: "1"} : {opacity: "0"}}>
                            {vinho.premios.map((vinho, i)=>(
                              <span key={"premios" + i}><span className={font.aGGD + " painel1Titles"}>{vinho.prize} - </span> {vinho.source}<br/></span>
                            ))}
                          </p>
                        ))}
                      </div>
                    </div>
                    </>
                  }

                  {/* FOURTH SLIDE */}
                  <div>
                    <table>
                      <tbody>
                        {vinho.capacidade !== "---" && <tr><td className= {font.aGGB + " table1"}>{t('infoTitles.capacidade')}</td><td className= {font.nSI + " table2"}>{vinho.capacidade}</td></tr>}
                        {vinho.unidadesCaixa !== "---" && <tr><td className= {font.aGGB + " table1"}>{t('infoTitles.garrafasCaixa')}</td><td className= {font.nSI + " table2"}>{vinho.unidadesCaixa}</td></tr>}
                        {vinho.pesoLiquido !== "---" && <tr><td className= {font.aGGB + " table1"}>{t('infoTitles.pesoCaixaLiquido')}</td><td className= {font.nSI + " table2"}>{vinho.pesoLiquido}</td></tr>}
                        {vinho.pesoBruto !== "---" && <tr><td className= {font.aGGB + " table1"}>{t('infoTitles.pesoCaixaBruto')}</td><td className= {font.nSI + " table2"}>{vinho.pesoBruto}</td></tr>}
                        {vinho.dimensoes !== "---" && <tr><td className= {font.aGGB + " table1"}>{t('infoTitles.dimensoes')}</td><td className= {font.nSI + " table2"}>{vinho.dimensoes}</td></tr>}
                        {vinho.caixasPalete !== "---" && <tr><td className= {font.aGGB + " table1"}>{t('infoTitles.caixasPalete')}</td><td className= {font.nSI + " table2"}>{vinho.caixasPalete}</td></tr>}
                        {vinho.pesoPalete !== "---" && <tr><td className= {font.aGGB + " table1"}>{t('infoTitles.pesoPalete')}</td><td className= {font.nSI + " table2"}>{vinho.pesoPalete}</td></tr>}
                      </tbody>
                    </table>
                  </div>
                </Slider>
              </div>
            </CSSTransition>
        </div>
      </div>
    </StyledVinho>
  )
}

export default VinhoModal

const StyledVinho = styled(Div100vh)`
  height:auto !important;
  color:#fff;

  .painel1Txt{
    letter-spacing:unset !important;

  }

  .first-panel{
    position: relative;
    height: calc(100vh - 50px) !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100vw;
    background: rgb(62,48,40);
    background: linear-gradient(180deg, rgba(62,48,40,1) 0%, rgba(62,48,40,1) 50%, rgba(31,24,19,1) 100%);
    z-index:3;

    .ficha-tenica-link{
      z-index:2;
    }
    
    .ficha-tecnica{
      border-radius:0;
      font-family: 'NSTR';
      letter-spacing:0;
      position: absolute;
      margin: 10px;
      padding: 9px 12px;
      font-size: 14px;

      &:hover{
        background:#fff;
        color:#3e3028;
      }
    }

    .first-content{
      z-index:2;
      text-align:center;
      width: 100%;
      padding: 0 25px;

      .vinhoNome{
        font-size:30px;
        margin:10px 0;
      }

      .tipoAno{
        .vinhoTipo{
          font-size:14px;
          margin-bottom:10px;
        }

        .vinhoAno{
          font-size:15px;
        }
      }
    }

    .vinhoImg{
      width: fit-content;
      z-index:2;
      display: flex;
      justify-content: center;

      img{
        height: 100%;
        width: 65% !important;
        z-index:1;
      }
    }

    button{
      position: absolute;
      top: 0;
      right: 0;
      display:flex;
      justify-content: flex-end;
      z-index:1;

      img{
        width: 30%;
        margin: 20px;
      }
    }

    .bigAno{
      position: absolute;
      font-size: 35vh;
      transform: rotate(-90deg);
      line-height: 0.7;
      color:#281E14;
      z-index:1;
      height: 100vw;
    }
  }

  .second-panel{
    position:relative;
    width:100vw;
    height: calc(80vh - 50px) !important;
    z-index:2;
    display: flex;
    flex-direction: column;
    justify-content: center;

    &:before{
      content: '';
      background: url(${props => props.bgFixed});
      background-size: 65%;
      background-repeat: no-repeat;
      background-position: center;
      width: 100%;
      height: 100%;
      position: fixed;
      opacity: 0.3;
      z-index: 0;
      pointer-events: none;
      max-width: 630px;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }

  .info{
    z-index:1;
  }

  h5{
    padding: 0 25px;
    text-align:center;
    text-decoration:underline;
    margin-bottom:25px;
    z-index:1;
  }

  .slick-track{
    display: flex;
    align-items:center;

    .slick-slide{
      padding: 0 25px;

      @media ${device.mobileXL}{
        padding: 0 60px;
      }

      .painel1Txt{
        font-size:14px;
      }
    }
  }

  .premiosAnos{
    margin: 20px 0;
    display: flex;
    flex-wrap: wrap;
    
    .colheita{
      font-size: 15px;
      height: 2.5em;
      border: 1px solid #fff;
      display: flex;
      align-items: center;
      text-align: center;
      justify-content: center;
      background-color: inherit;
      transition: all 0.3s ease;
      position:relative;
      padding: 10px;
      margin: 3px;
    }

    .colheita-active{
      background:#fff;
      color:#3e3028;
    }
  }

  .premiosDisplay{
    position: relative;
    min-height: 15vh;

    .premios{
      position: absolute;
    }
  }

  table{
    width: 100%;
    padding: 0;
    border-spacing: 0;
    border-top: 2px solid rgba(21, 14, 3, 0.5);
    font-size: 18px;
    vertical-align: middle;

    tbody{
      font-size:15px;
    }

    .table1{
      width: 66%;
      margin: 0;
      border-left: 2px solid rgba(21, 14, 3, 0.5);
      border-bottom: 2px solid rgba(21, 14, 3, 0.5);
      padding: 0 15px;
      height: 2.5em;
    }

    .table2{
      width: 34%;
      text-align: center;
      margin: 0;
      border-right: 2px solid rgba(21, 14, 3, 0.5);
      border-left: 2px solid rgba(21, 14, 3, 0.5);
      border-bottom: 2px solid rgba(21, 14, 3, 0.5);
      height: 2.5em;
    }
  }

  .prev-arrow img, .next-arrow img{
    width:35px;
  }

  .prev-arrow{
    left:0;
    bottom:0;
    top: unset;
  }

  .next-arrow{
    right:0%;
    bottom:0;
    top: unset;
  }

  .vinhoImgFixed{
      display: flex;
      justify-content: center;
      align-items: center;
      height: calc(100vh - 50px);
      position: fixed;
      top: 0;
      pointer-events:none;
      margin-top:50px;
      background: rgb(62,48,40);
      background: linear-gradient(180deg, rgba(62,48,40,1) 0%, rgba(62,48,40,1) 50%, rgba(31,24,19,1) 100%);

      img{
        width: 65% !important;
        opacity: 0.3;
      }
  }
`