import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout/layout"
import SEO from "../components/seo"
import { useBreakpoint } from 'gatsby-plugin-breakpoints'
import { device } from "../components/device"
import SidebarMobile from '../components/sidebar/mobile'
import WineFilter from '../components/sidebar/wine-filter'

import styled from "styled-components"
import font from "../fonts/fonts.module.scss"
import Div100vh from 'react-div-100vh'
import { Button } from "../components/buttons"

import linkify from "../custom/linkify"

import Sidebar from "../components/sidebar/sidebar"
import VinhoInfo from "../components/vinhos/vinhoModalInfo"
import VinhoInfoMobile from "../components/vinhos/vinhoModalInfoMobile"
import setaD from "../images/setaD.svg"

const WineTemplate = ({ pageContext }) => {
  const breakpoints = useBreakpoint()
  const linkNext = (linkify(pageContext.next.regiao, pageContext.next.nome));

  let regiao = pageContext.vinho.regiao

  return (
    <Layout>
      <SEO title={"Saven - " + pageContext.vinho.nome} />
      {!breakpoints.mobile ?
        <Sidebar switchMenu="right" menuBtn/>
      :
        <SidebarMobile brown/>
      }
      {breakpoints.mobile &&
        <WineFilter white="isWhite"/>
      }
      <StyledContent regiao={regiao}>
      {!breakpoints.mobile &&
        <div className="vinhoModal">
          <VinhoInfo data={pageContext.vinho}/>
            <div className="vinhoNext">
              <Link as={Button} styleless to={"/vinhos/" + linkNext}>
                <img src={setaD} alt="Seta para a direita"/>
              </Link>
              <h5 className={font.aGGD}>{pageContext.next.nome}</h5>
              <h5 className={font.nSI}>{<span>{pageContext.next.tipo}<br/>{pageContext.next.regiao}</span>}</h5>
              <div className="nxtImg">
                <img src={pageContext.next.img.publicURL} alt="Próximo vinho" style={{ width: `100%`, height: "auto", opacity: "0.3" }} className="vinhoImg"/>
              </div>
            </div>
        </div>
      }
      {breakpoints.mobile &&
        <div className="vinhoModal-mobile">
          <VinhoInfoMobile data={pageContext.vinho}/>
          <Link as={Button} styleless to={"/vinhos/" + linkNext}>
            <div className="vinhoNext-mobile">
              <h5 className={font.aGGD}>{pageContext.next.nome}</h5>
              <h5 className={font.nSI}>{<span>{pageContext.next.tipo}<br/>{pageContext.next.regiao}</span>}</h5>
              <div className="nxtImg-mobile">
                <img src={pageContext.next.img.publicURL} alt="Próximo vinho" style={{ width: `100%`, height: "auto", opacity: "0.3" }} className="vinhoImg-mobile"/>
              </div>
            </div>
            </Link>
        </div>
      }
      </StyledContent>
    </Layout>
  )
}

export default WineTemplate

const StyledContent = styled(Div100vh)`
  z-index: 5;
  top: 0;
  display: flex;
  background: #150e04;
  overflow: hidden;
  margin-top:50px;
  height:auto !important;

  @media ${device.desktop}{
    margin-left: 200px;
    margin-top:0;
    width: 84.35%;
  }

  @media ${device.desktopL}{
    margin-left: 15.65%;
  }
  
  .vinhoModal{
    width: 100%;
    display: flex;
    background: #fff;
  }

  .vinhoNext{
    margin-left: 20px;
    width: 200px;
    background: #150e03;
    position: relative;
    color: #fff8f4;
    padding: 40px 20px;

    h5{
      margin: 15px 0;
      font-size: 16px;
    }

    .nxtImg{
      position: absolute;
      right: ${props => props.regiao === 'Regional - Mesa' ? '30%' : '50%'};
      top: 50%;
      width: 25vh;
      transform: translate(50%, -50%);
    }
  }

  /* MOBILE */

  .vinhoNext-mobile{
    background: #150e03;
    position: relative;
    color: #fff8f4;
    padding: 40px 20px;
    border-top: solid 5px #fff;
    padding: 10px 40% 10px 40px;
    z-index:2;
    height:20vh;

    h5{
      margin: 15px 0;
      font-size:15px;
    }

    .nxtImg-mobile{
      position: absolute;
      top: 0;
      height: 100%;
      width: 100%;
      left: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      transform: rotate(90deg);
      margin-left: 15%;
      pointer-events:none;

      img{
        width:150px !important;
      }
    }
  }
`